/* eslint-disable no-use-before-define */
import _find from 'lodash/find';
import _omit from 'lodash/omit';
import _without from 'lodash/without';
import _uniq from 'lodash/uniq';
import _filter from 'lodash/filter';
import _intersectionWith from 'lodash/intersectionWith';
import _pick from 'lodash/pick';
import { createAction } from 'redux-actions';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';

import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { Currency } from 'core/../react-billing/constants';

import DeviceProps from '@magnus/react-native-device-props';
import Mutator from '@magnus/react-native-mutator';

import { t } from '@web-solutions/module-localization';
//@ts-ignore
import Billing from '@web-solutions/module-billing';
import { mappingPricesToProducts, PaymentSystem, ProductInfo, type SolidgatePayPalOrder, type SolidgateMercadoPagoOrder, } from '@web-solutions/react-billing';
import Analytics from '@web-solutions/module-analytics';

import { get3DSRedirectURLs } from '@web-solutions/core/utils/billing'
import { getVATMetadata } from '@web-solutions/core/utils/get-vat-metadata'

import type { SubscribeOnOneClickParams, SubscribeOnOneClickResponse } from '@web-solutions/core/store/billing/types';

import { reInit as reInitRemoteConfig } from '@web-solutions/core/store/remote-config/actions';
import { ManageSubscription } from '@web-solutions/manage-subscription/src/manage/types';

import { stringifyUrlParams } from 'core/utils/url-sync';
import { CreatePayPalOrder, PaddleConfig, Purchase, SolidMetadata, Subscription, SubscriptionProps } from 'core/interfaces/billing';
import { InjectedPaymentMethod, ProductConfig, RemoteConfig } from 'core/constants/remote-config';
import { ModePayPal } from 'core/constants/billing';

//@ts-ignore
import { processEmail } from 'src/store/profile/actions';

import { remoteConfigSelector } from '../remote-config/selectors';

import { getProductsIds, prepareProducts } from './utils';
import { ProductDetails, selectPaymentProject, selectPaymentSystem, selectAllProducts, selectPurchased, selectOneTimePurchases, } from './selectors';

import * as TYPES from './types';

import { BillingState, PaymentMethod, type OneTimePurchase } from './index';

dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(utc)

const setPaymentSystem = createAction(TYPES.SET_PAYMENT_SYSTEM, (paymentSystem: PaymentSystem) => ({ paymentSystem }));
export const setPurchase = createAction(TYPES.SET_PURCHASE, (purchase: Purchase) => ({ purchase }));
export const setSubscription = createAction(TYPES.SET_SUBSCRIPTION);
export const resetPurchase = createAction(TYPES.RESET);
export const setIsSubmitByCard = createAction<boolean>(TYPES.SET_IS_SUBMIT_BY_CARD);
export const setLoading = createAction(TYPES.SET_LOADING);
export const setPending = createAction(TYPES.SET_PENDING);
const setOrderDetails = createAction(TYPES.SET_ORDER_DETAILS);
const setOrdersPayPal = createAction<Record<string, SolidgatePayPalOrder>>(TYPES.SET_ORDERS_PAYPAL);
export const setOrderPending = createAction(TYPES.SET_ORDER_PENDING);
export const setPaddleConfig = createAction<PaddleConfig>(TYPES.SET_PADDLE_CONFIG);
export const setProducts = createAction<ProductInfo[]>(TYPES.SET_PRODUCTS);
export const setTrialPrice = createAction(TYPES.SET_TRIAL_PRICE);
const setDiscountEndDate = createAction(TYPES.SET_DISCOUNT_END_DATE);
const setNoFundsOfferEndDate = createAction(TYPES.SET_NO_FUNDS_END_DATE);
const setSpecialOfferEndDate = createAction(TYPES.SET_SPECIAL_OFFER_END_DATE);
const setSpecialOfferProducts = createAction(TYPES.SET_SPECIAL_OFFER_PRODUCTS);
const setNoFundsOfferProducts = createAction(TYPES.SET_NO_FUNDS_OFFER_PRODUCTS);
const setCountryCode = createAction(TYPES.SET_COUNTRY_CODE);
export const setPostCode = createAction<string>(TYPES.SET_POSTCODE);
export const setInjectedPaymentMethod = createAction<InjectedPaymentMethod[]>(TYPES.SET_INJECTED_PAYMENT_METHOD);
export const setOneTimePurchases = createAction<OneTimePurchase[]>(TYPES.SET_ONE_TIME_PURCHASES);
export const setPaymentMethod = createAction<PaymentMethod | null>(TYPES.SET_PAYMENT_METHOD);
export const setTrialsLeftCounter = createAction(TYPES.SET_TRIALS_LEFT_COUNTER);
export const setUpsaleToBasket = createAction(TYPES.SET_UPSALE_TO_BASKET);
export const updateUpsaleInBasket = createAction(TYPES.UPDATE_UPSALE_IN_BASKET);
export const setOneTimePurchaseId = createAction(TYPES.SET_ONE_TIME_PURCHASE_ID);
export const setActiveProduct = createAction(TYPES.SET_ACTIVE_PRODUCT);

let initPromise: Promise<any> | null;

export const initBilling = (): ThunkAction<Promise<any>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();

    const paymentSystem = selectPaymentSystem(state);
    const paymentProject = selectPaymentProject(state);

    try {
      const oneTimePurchases = await Billing.getOneTimePurchases();

      dispatch(setOneTimePurchases(oneTimePurchases));
    } catch (e) {
      console.log('[ERROR GET ONE TIME PURCHASES]:', e);
    }

    if (paymentSystem === PaymentSystem.PADDLE) {
      Promise.all([
        Mutator.getCountryCode(),
        DeviceProps.getIDFM(),
      ])
        .catch(() => ([]))
        .then(([country, idfm]) => {
          dispatch(setPaddleConfig({
            country,
            passthrough: idfm
              ? JSON.stringify({
                idfm,
                payment_system_project: paymentProject,
              })
              : undefined,
          }));
        });
    }

    return Billing.init(paymentSystem, paymentProject)
  };

export const init = ({ isInitBillingOnly = false }: { isInitBillingOnly?: boolean } = {}): ThunkAction<Promise<any>, any, unknown, any> =>
  async (dispatch) => {
    if (!initPromise) {
      dispatch(setLoading(true));

      const p = [
        await dispatch(initBilling()),
        ...(!isInitBillingOnly ? [dispatch(initDiscount()), dispatch(initProducts()).then(() => dispatch(initOrders()))] : [])
      ];

      initPromise = Promise.all(p)
        .catch((error) => {
          console.log('[ERROR INIT BILLING]:', error);
          return false;
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }

    return initPromise;
  };


export const checkRedirectResults = (): ThunkAction<Promise<Subscription | null>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();
    const {
      billing: { paddleConfig, },
    } = state;

    const qs = queryString.parse(window.location.search);

    if (qs.success === PaymentSystem.PADDLE) {
      let checkout_id = qs.checkout_id || paddleConfig?.checkoutId;
      let product_id = qs.product_id || paddleConfig?.productId;
      let payment_method = qs.payment_method || paddleConfig?.paymentMethod;
      if (checkout_id) {
        createBrowserHistory()
          .replace(
            queryString.stringifyUrl({
              url: window.location.pathname,
              query: (_omit(qs, ['success', 'checkout_id', 'product_id', 'payment_method'])),
            })
          );
        return dispatch(createCustomer({ paymentSystem: PaymentSystem.PADDLE }))
          .then(() => dispatch(subscribe({
            checkout_id: checkout_id,
            price_id: product_id,
            method: payment_method,
            paymentSystem: PaymentSystem.PADDLE,
          })));
      }
    } else if (qs.success === 'mercadopago') {
      createBrowserHistory()
        .replace(
          queryString.stringifyUrl({
            url: window.location.pathname,
            query: (_omit(qs, ['success', 'product_id', 'amount', 'currency'])),
          })
        );
      Analytics.trackEvent('mercadopago', 'success');
      const order_id = window.localStorage.getItem('mercadopago_order_id') || undefined;
      try {
        return dispatch(subscribe({
          order_id,
          amount: String(qs.amount),
          currency: String(qs.currency),
          price_id: String(qs.product_id),
          paymentSystem: PaymentSystem.SOLIDGATE,
          method: 'mercadopago',
        }));
      }
      catch (err) {
        dispatch(handleErrorPurchase(err));
      }
    }
    else if (qs.error === 'mercadopago') {
      createBrowserHistory()
        .replace(
          queryString.stringifyUrl({
            url: window.location.pathname,
            query: (_omit(qs, ['error'])),
          })
        );
      const e = {
        paymentSystem: PaymentSystem.SOLIDGATE,
        method: 'mercadopago',
      };
      dispatch(handleErrorPurchase(e));
      throw e;
    }
    return null;
  };

export const initProducts = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();

    const paymentSystem = selectPaymentSystem(state);

    const { products, specialOffer, noFundsOffer } = state.remoteConfig as RemoteConfig;

    const PRODUCTS_MAP = [
      {
        products: prepareProducts({ products: products, paymentSystem, attachment: 'default' }),
        condition: products?.length,
        productsSetter: setProducts
      },
      {
        products: prepareProducts({ products: specialOffer?.products, paymentSystem, attachment: 'specialOffer' }),
        condition: specialOffer?.enabled && specialOffer?.products?.length,
        productsSetter: setSpecialOfferProducts
      },
      {
        products: prepareProducts({ products: noFundsOffer?.products, paymentSystem, attachment: 'noFundsOffer' }),
        condition: noFundsOffer?.enabled && noFundsOffer?.products?.length,
        productsSetter: setNoFundsOfferProducts
      }
    ]

    const allProducts = PRODUCTS_MAP.reduce<ProductConfig[]>((acc, cur) => ([...acc, ...cur.products]), [])

    const fetchedProducts = await dispatch(fetchProducts(allProducts));

    PRODUCTS_MAP.forEach(({ products, condition, productsSetter }) => {
      if (condition) {
        //TODO: add auto test for checking products map with same price ids
        const currentProducts = _intersectionWith(fetchedProducts, products, (cur, inc) => cur.id === inc.id && cur.attachment === inc.attachment);
        dispatch(productsSetter(currentProducts));
      }
    })
  };

export const initSpecialOfferEndDate = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();
    const { specialOffer } = state.remoteConfig as RemoteConfig;
    dispatch(setSpecialOfferEndDate(dayjs().add(specialOffer.time, 'second')));
  };

export const initNoFundsOfferEndDate = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();
    const { specialOffer } = state.remoteConfig as RemoteConfig;
    dispatch(setNoFundsOfferEndDate(dayjs().add(specialOffer.time, 'second')));
  };

export const getPaymentMethod = (): ThunkAction<Promise<PaymentMethod | null>, any, unknown, any> =>
  async (dispatch) => {
    const res = (await Billing.getPaymentMethod()) as any;
    const paymentMethod = res.payment_method;
    dispatch(setPaymentMethod(paymentMethod));
    return paymentMethod;
  }

export const getOneTimePurchases = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch) => {
    const oneTimePurchases = (await Billing.getOneTimePurchases());
    dispatch(setOneTimePurchases(oneTimePurchases));
  }

export const fetchProducts = (products: ProductConfig[]): ThunkAction<Promise<ProductInfo[]>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();

    const {
      productsDetails,
      displayVAT
    } = state.remoteConfig as RemoteConfig;

    const paymentSystem = selectPaymentSystem(state);

    const [country, prices] = await Promise.all([
      Mutator.getCountryCode(),
      Billing.getPrices(getProductsIds(products), { paymentSystem }),
    ]);

    dispatch(setCountryCode(country?.toLowerCase()));

    return mappingPricesToProducts({ products, prices: [...prices, ..._filter(productsDetails, { period: 'ONETIME' })], countryCode: country || '', displayVAT })
  };

export const initOrders = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();

    const products = selectAllProducts(state);
    const paymentSystem = selectPaymentSystem(state);
    const { modePayPal } = remoteConfigSelector(state);

    const res = await dispatch(createCustomer({ paymentSystem }));

    if (!!res?.email) {
      dispatch(processEmail(res.email));
    }

    const product = products.find((product) => product.default) || products[0];
    if (product) {
      if (paymentSystem === PaymentSystem.SOLIDGATE) {
        dispatch(
          createOrders({
            product,
            products: products,
            currency: product.currency,
            paymentSystem: PaymentSystem.SOLIDGATE,
          }),
        );

        if (modePayPal === ModePayPal.SOLIDGATE) {

          const payPalProduct = product.isOneTimePurchase ? { paymentSystem, one_time_products: [product] } : { paymentSystem, products: [product], currency: product.currency!, }
          const payPalProducts = product.isOneTimePurchase ? { paymentSystem, one_time_products: _without(products, product) } : { paymentSystem, products: _without(products, product), currency: product.currency!, }

          dispatch(
            createPayPalOrders(payPalProduct)
          );
          dispatch(
            createPayPalOrders(payPalProducts)
          );
        }

      } else if (paymentSystem === PaymentSystem.PADDLE) {
        const product = products.find((product) => product.default) || products[0];
        dispatch(
          createOrders({
            product: { ...product, id: product.solidgateId! },
            products: products.map(p => ({ ...p, id: p.solidgateId })).filter(p => !!p.id) as ProductDetails[],
            currency: product.currency,
            paymentSystem: PaymentSystem.SOLIDGATE,
          }),
        );
      }
    }
  }

const initDiscount = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const {
      remoteConfig: { discountTime: time },
      billing: { discountEndDate },
    } = getState();

    if (time) {
      if (!discountEndDate) {
        dispatch(setDiscountEndDate(dayjs().add(time, 'second')));
      }
    } else {
      if (discountEndDate) {
        dispatch(setDiscountEndDate(null));
      }
    }
  };

export const createCustomer =
  ({ email, paymentSystem, paymentProject }: { email?: string, paymentSystem?: PaymentSystem, paymentProject?: string } = {}): ThunkAction<Promise<{ email: string }>, any, unknown, any> =>
    async (dispatch, getState) => {
      try {
        const { profile } = getState();
        // Get email from form, otherwise get it from profile. Customer created from paypal won't have email
        email = email || profile.email;
        if (email) {
          Analytics.setUserProperty('email', email);
        }
        dispatch(setPending(true));
        return await Billing.createCustomer({ email, paymentSystem, paymentProject }) as any;
      } catch (error) {
        console.log('[ERROR ASSIGN CUSTOMER]', error);
        throw error;
      } finally {
        dispatch(setPending(false));
      }
    };

const createOrders =
  ({ product, products, currency, paymentSystem }:
    { product: ProductDetails, products: ProductDetails[], currency: Currency, paymentSystem?: PaymentSystem }): ThunkAction<Promise<void>, any, unknown, any> =>
    async (dispatch, getState) => {
      const idfm = await DeviceProps.getIDFM();

      const state = getState()

      const { billing: { countryCode } } = state

      const { isSendingTerminateLinks, isSendingTrialEnd, displayVAT } = remoteConfigSelector(state);

      try {
        dispatch(setOrderDetails(null));
        dispatch(setOrderPending(true));
        const orderDetails = await Billing.createOrder({
          ...(product.isOneTimePurchase
            ? {
              one_time_product: {
                product: product.id,
                amount: product.amount,
                currency: product.currency,
              },
              one_time_products: products.map(p => ({
                product: p.id,
                amount: p.amount,
                currency: p.currency,
              })),
            }
            : {
              productId: product.id,
              products: _uniq(getProductsIds(products)),
              currency,
            } as any
          ),
          orderDescription: idfm,
          paymentSystem,
          metadata: getVATMetadata({ countryCode, isAvailable: displayVAT && !product.isOneTimePurchase }),
          is_sending_terminate_links: isSendingTerminateLinks,
          is_sending_trial_end: isSendingTrialEnd,
        });

        dispatch(setOrderDetails({ ...orderDetails, productId: product.id }));
      } catch (error) {
        console.log('[ERROR CREATE ORDER]', error);
        throw error;
      } finally {
        dispatch(setOrderPending(false));
      }
    };



export const createPayPalOrders =
  ({
    one_time_products,
    products,
    currency,
    paymentSystem,
    trigger,
    metadata,
    solidMetadata,
  }: CreatePayPalOrder): ThunkAction<Promise<void>, any, unknown, any> =>
    async (dispatch, getState) => {
      const idfm = await DeviceProps.getIDFM();

      const state = getState()

      const { billing: { countryCode } } = state

      const { isSendingTerminateLinks, isSendingTrialEnd, displayVAT } = remoteConfigSelector(state);

      dispatch(setOrdersPayPal({}));

      const currentProducts = products || one_time_products

      if (currentProducts?.length) {
        Billing.initAlternativePayment({
          ...(currentProducts[0]?.isOneTimePurchase
            ? {
              one_time_products: one_time_products?.map(p => ({
                product: p.id,
                amount: p.amount,
                currency: p.currency,
              })),
            }
            : {
              products: products?.map(p => p.id),
            }),
          solid_metadata: solidMetadata,
          currency,
          metadata: {
            ...getVATMetadata({ countryCode, isAvailable: displayVAT && !products?.[0]?.isOneTimePurchase }),
            ...(metadata ? metadata : {})
          },
          paymentSystem,
          trigger,
          orderDescription: idfm,
          is_sending_terminate_links: isSendingTerminateLinks,
          is_sending_trial_end: isSendingTrialEnd,
          paymentMethod: 'paypal',
        } as any)
          //@ts-ignore
          .then((orders: Record<string, SolidgatePayPalOrder>) => {
            dispatch(setOrdersPayPal(orders));
          });
      }
    };


export const initMercadoPagoPayment =
  (data: {
    product: ProductDetails,
    trigger?: string,
    metadata?: any,
    solidMetadata?: SolidMetadata,
    first_name: string,
    last_name: string,
    taxpayer_id: string,
  }): ThunkAction<Promise<void>, any, unknown, any> =>
    async (dispatch, getState) => {
      const idfm = await DeviceProps.getIDFM();

      dispatch(setPending(true));

      const state = getState()

      const { billing: { countryCode } } = state

      const { isSendingTerminateLinks, isSendingTrialEnd, displayVAT } = remoteConfigSelector(state);

      Analytics.trackEvent('mercadopago', 'init_request');

      // not full href because url is 256 chars max
      const requiredParams = _pick(queryString.parse(window.location.search), ['idfm', 'mode', 'email', 'completed']);

      return Billing.initAlternativePayment({
        ...(data.product.isOneTimePurchase
          ? {
            one_time_products: [{
              product: data.product.id,
              amount: data.product.amount,
              currency: data.product.currency,
            }],
          }
          : {
            products: [data.product.id],
          }),
        solid_metadata: data.solidMetadata,
        currency: data.product.currency,
        metadata: {
          ...getVATMetadata({ countryCode, isAvailable: displayVAT && !data.product?.isOneTimePurchase }),
          ...(data.metadata ? data.metadata : {})
        },
        paymentSystem: PaymentSystem.SOLIDGATE,
        trigger: data.trigger,
        orderDescription: idfm,
        is_sending_terminate_links: isSendingTerminateLinks,
        is_sending_trial_end: isSendingTrialEnd,
        paymentMethod: 'mercadopago',
        first_name: data.first_name,
        last_name: data.last_name,
        taxpayer_id: data.taxpayer_id,
        success_url: queryString.stringifyUrl({
          url: window.location.origin + window.location.pathname,
          query: {
            success: 'mercadopago',
            product_id: data.product.id,
            amount: data.product.trial_price_amount || data.product.amount,
            currency: data.product.currency,
            ...requiredParams,
          }
        }),
        fail_url: queryString.stringifyUrl({
          url: window.location.origin + window.location.pathname,
          query: {
            error: 'mercadopago',
            ...requiredParams,
          }
        }),
        return_url: queryString.stringifyUrl({
          url: window.location.origin + window.location.pathname,
          query: {
            error: 'mercadopago',
            ...requiredParams,
          }
        }),
      } as any)
        .then((r: any) => {
          return r[data.product.id] || r;
        })
        .then((r: SolidgateMercadoPagoOrder | { error: { messages?: string[], code?: string, } }) => {
          if ('error' in r) {
            const e = new Error(Object.values(r.error.messages || {}).flat().join(', ') || 'Unknown error');
            //@ts-ignore
            e.code = r.error?.code;
            throw e;
          }
          const url = r?.pay_form?.return_url;
          if (url) {
            Analytics.trackEvent('mercadopago', 'redirect_to_ckeckout');
            Analytics.flush();

            window.localStorage.setItem('mercadopago_order_id', r.order.order_id);
            setTimeout(() => {
              window.location.href = url;
            }, 100);
          } else {
            throw new Error('No return url found');
          }
        })
        .catch((e: any) => {
          Analytics.trackEvent('mercadopago', 'init_error', {
            error: e.message,
          });
          e.method = 'mercadopago';
          e.paymentSystem = PaymentSystem.SOLIDGATE;
          dispatch(handleErrorPurchase(e));
          dispatch(setPending(false));
          throw e;
        });
    };

interface CreateCheckout {
  productId: string,
  checkoutId: string,
  paymentMethod: PaymentMethod,
  paymentSystem: PaymentSystem
}

export const createCheckout =
  ({ productId, checkoutId, paymentMethod, paymentSystem }: CreateCheckout): ThunkAction<Promise<void>, any, unknown, any> =>
    async (dispatch, getState) => {
      try {
        const p = queryString.parse(window.location.search);
        const successUrl = queryString.stringifyUrl({
          url: window.location.origin + window.location.pathname,
          query: {
            ...p,
            success: PaymentSystem.PADDLE,
          },
        });

        await Billing.createCheckout({
          productId,
          checkoutId,
          paymentMethod,
          successUrl,
          paymentSystem,
        });
      } catch (error) {
        console.log('[ERROR CREATE CHECKOUT]', error);
        throw error;
      }
    };

export const handleSuccessPurchase = (subscription: Subscription): ThunkAction<Promise<Subscription>, any, unknown, any> =>
  async (dispatch, getState) => {
    const { transaction_id, price_id, plan_name, amount, currency, method, isOneTimePurchase } = subscription;

    const actualPlanId = price_id ?? plan_name

    const state = getState();

    const products = selectAllProducts(state);
    const purchased = selectPurchased(state);
    const oneTimePurchases = selectOneTimePurchases(state);

    const {
      profile: { email },
    } = state;

    const paymentSystem = selectPaymentSystem(state);

    const p = _find(products, { id: actualPlanId }) || _find(products, { paypalPlanId: actualPlanId });

    if (subscription.email || email || subscription.first_name || subscription.last_name) {
      Analytics.trackEvent('user', 'info', {
        email: subscription.email || email || undefined,
        first_name: subscription.first_name || undefined,
        last_name: subscription.last_name || undefined,
      });
    }

    if (!purchased && !isOneTimePurchase) {
      Analytics.trackPurchaseEvent({
        transactionId: transaction_id,
        productId: actualPlanId,
        revenue: +(p?.firstPaymentAmount ?? amount),
        currency,
        method,
        paymentSystem: subscription.paymentSystem || paymentSystem,
      });
    }

    if (!isOneTimePurchase || !purchased) {
      dispatch(setPurchase({
        ...subscription,
        isTrial: !!p?.isTrial,
        product: p!,
        paymentSystem: subscription.paymentSystem || paymentSystem,
      }));
    }

    if (isOneTimePurchase) {
      dispatch(setOneTimePurchases([
        ...oneTimePurchases,
        {
          product_code: price_id ?? plan_name,
          payment_service: subscription.paymentSystem || paymentSystem,
          currency,
        }
      ]));
    }

    Analytics.setUserProperty('paymentMethod', subscription?.method);
    dispatch(setPaymentMethod(subscription?.method))
    await dispatch(reInitRemoteConfig());

    stringifyUrlParams({ purchased: null });

    return subscription;
  };

export const handleErrorPurchase = (error: any, { noToast }: { noToast: boolean | undefined } = { noToast: false }): ThunkAction<void, any, unknown, any> => (dispatch, getState) => {
  console.warn(error, 'handleErrorPurchase');

  if (!error?.data?.three_d_secure_action_token_id) {
    let customMessage;
    if (error?.paymentSystem === PaymentSystem.SOLIDGATE) {
      customMessage = t(`core.solidgate_payment_errors`, { returnObjects: true })[error?.code];
    }

    if (!noToast) {
      toast(customMessage || error?.message || 'Something went wrong', { type: 'error', autoClose: customMessage ? 7500 : 5000 });
    }

    Analytics.trackEvent('ecommerce', 'error', {
      message: error?.message,
      code: error?.code,
      paymentSystem: error?.paymentSystem,
      method: error?.method,
    });
  }
};

export const subscribe = (formData: SubscriptionProps): ThunkAction<Promise<Subscription>, any, unknown, any> =>
  async (dispatch, getState) => {
    const {
      profile: { email },
      remoteConfig: { isSendingTerminateLinks, isSendingTrialEnd, displayVAT },
      billing: { countryCode }
    } = getState();

    try {
      dispatch(setPending(true));

      // If we have no email in form, then get it from profile.
      if (!formData.email && email) {
        formData.email = email;
      }

      if (formData.email) {
        Analytics.setUserProperty('email', formData.email);
      }
      let subscriptionDetails = {
        ...formData
      }

      try {
        const subscriptionRes = await Billing.subscribe({
          ...formData,
          metadata: getVATMetadata({ countryCode, isAvailable: displayVAT }),
          is_sending_terminate_links: isSendingTerminateLinks,
          is_sending_trial_end: isSendingTrialEnd,
        } as any);

        subscriptionDetails = {
          ...subscriptionRes,
          ...subscriptionDetails,
        };

      } catch (e: any) {
        const ps = formData.paymentSystem || e?.paymentSystem;
        if (ps !== PaymentSystem.SOLIDGATE) {
          throw e;
        }
      }
      return dispatch(handleSuccessPurchase(subscriptionDetails as any));
    } catch (error: any) {
      const err = error || {};
      err.paymentSystem = formData.paymentSystem || err.paymentSystem;
      err.method = formData.method || err.method;
      throw error;
    } finally {
      dispatch(setPending(false));
    }
  };

export const applePayOrder = (formData: any, options?: { trigger?: string, metadata?: any, solidMetadata?: SolidMetadata }): ThunkAction<Promise<Subscription>, any, unknown, any> =>
  async (dispatch, getState) => {
    const {
      profile: { email },
      billing: { countryCode },
      remoteConfig: { isSendingTerminateLinks, isSendingTrialEnd, displayVAT },
    } = getState();

    try {
      dispatch(setPending(true));

      const idfm = await DeviceProps.getIDFM();

      // If we have no email in form, then get it from profile.
      if (!formData.email && email) {
        formData.email = email;
      }

      if (formData.email) {
        Analytics.setUserProperty('email', formData.email);
      }

      const subscriptionRes: any = await Billing.applePayOrder({
        ...formData,
        trigger: options?.trigger,
        metadata: options?.metadata || getVATMetadata({ countryCode, isAvailable: displayVAT }),
        solidMetadata: options?.solidMetadata,
        is_sending_terminate_links: isSendingTerminateLinks,
        is_sending_trial_end: isSendingTrialEnd,
        description: idfm,
      });

      const subscriptionDetails = {
        ...formData,
        ...subscriptionRes,
        amount: subscriptionRes?.order?.amount / 100 || undefined,
        currency: subscriptionRes?.order?.currency,
        method: 'applepay',
        transaction_id: subscriptionRes?.order?.subscription_id || subscriptionRes?.order_id,
      };

      return dispatch(handleSuccessPurchase(subscriptionDetails));
    } catch (error: any) {
      const err = error || {};
      err.paymentSystem = formData.paymentSystem || err.paymentSystem;
      err.method = formData.method || err.method;
      throw error;
    } finally {
      dispatch(setPending(false));
    }
  };

export const checkActiveSubscription = (args: { actualized?: boolean }): ThunkAction<Promise<[] | undefined>, any, unknown, any> =>
  async (dispatch, getState) => {
    try {
      const { billing: { purchase } } = getState();

      if (purchase) {
        const { data } = await Billing.getSubscriptions(args?.actualized) as any;

        if (data) {
          const isActiveSubscription = data.some((subscription: any) => subscription?.active);

          if (!isActiveSubscription) {
            window.localStorage.clear();
            const p = queryString.parse(window.location.search);
            delete p.completed;
            delete p.purchased;
            createBrowserHistory().replace(window.location.pathname + '?' + queryString.stringify(p));
          }
          return data;
        }
      }
    } catch (error) {
      console.log('[ERROR GET SUBSCRIPTIONS]', error);
    }
  };

export const changePlan = (productId: string, subscriptionId: string, isNewSubscription: boolean): ThunkAction<Promise<any>, any, unknown, any> =>
  async (dispatch, getState) => {
    dispatch(setPending(true));
    try {
      const { failUrl, successUrl } = get3DSRedirectURLs()

      const r = ((await Billing.changePlan({ subscriptionId, productId, isNewSubscription, successUrl, failUrl })) || {});

      console.log(r);

      return r;
    } finally {
      dispatch(setPending(false));
    }
  }

export const switchToReservePaymentSystem = (): ThunkAction<Promise<void>, any, unknown, any> =>
  async (dispatch, getState) => {
    const state = getState();
    const paymentSystem = selectPaymentSystem(state);
    const { paymentSystemReserve } = state.remoteConfig;

    if (paymentSystemReserve && paymentSystem !== paymentSystemReserve) {
      dispatch(setPaymentSystem(paymentSystemReserve));
      initPromise = null;
      dispatch(init());
    }
  }

export interface OneClickPaymentArgs {
  amount: number,
  currency: Currency,
  productCode: string,
  withTransactionDetailsEmail?: boolean,
  force3ds?: boolean,
  trigger?: string,
  metadata?: { chatId?: string }
  solidMetadata?: SolidMetadata;
}

export const subscribeOnOneClick = (params: SubscribeOnOneClickParams): ThunkAction<Promise<SubscribeOnOneClickResponse>, any, unknown, any> =>
  async (_, getState) => {
    const {
      billing: { countryCode },
      remoteConfig: { displayVAT }
    } = getState();

    const { failUrl, successUrl } = get3DSRedirectURLs()

    return await Billing.subscribeOnOneClick({
      ...params,
      metadata: getVATMetadata({ countryCode, isAvailable: displayVAT }),
      success_url: successUrl,
      fail_url: failUrl,
    }) as any
  }

export const createOneClickPayment = ({
  amount,
  currency,
  productCode,
  trigger = '',
  force3ds,
  metadata,
  solidMetadata,
  withTransactionDetailsEmail,
}: OneClickPaymentArgs): ThunkAction<Promise<void>, any, unknown, any> =>
  async () => {
    const { failUrl, successUrl } = get3DSRedirectURLs()

    const description = await DeviceProps.getIDFM();
    return await Billing.createOneClickPayment({
      withTransactionDetailsEmail,
      force3ds,
      amount,
      currency,
      productCode,
      successUrl,
      failUrl,
      trigger,
      metadata,
      solidMetadata,
      description
    }) as any;
  }

export const giveProduct = (productId: string): ThunkAction<Promise<void>, any, unknown, any> =>
  async () => {
    return Billing.giveProduct(productId);
  }

export const getSubscription = (actualized?: boolean): ThunkAction<Promise<ManageSubscription | undefined>, BillingState, unknown, Action<string>> => async (dispatch) => {
  const subscriptions = ((await Billing.getSubscriptions(actualized))?.data || []) as ManageSubscription[];
  const subscription = subscriptions.find((i: ManageSubscription) => i!.active) || subscriptions.pop();

  if (subscription) {
    dispatch(setSubscription(subscription));
  }

  return subscription
}

