import { type FC, Fragment } from 'react';

import { t } from '@web-solutions/module-localization';

import { useRemoteConfig } from 'src/hooks/use-remote-config';

import classes from './style.module.scss';

const tKey = 'footer';

export const Footer: FC = () => {
  const { policyLinks } = useRemoteConfig();

  const links = {
    privacy_policy: policyLinks.privacy,
    terms_of_service: policyLinks.termsOfUse,
    billing_terms: policyLinks.billingTerms,
  } as Record<string, string>;

  return (
    <footer className={classes.footer}>
      <p className={classes.footer__allRightsReserved}>
        {t(`${tKey}.all_rights_reserved`, { year: new Date().getFullYear() })}
      </p>
      <div className={classes.footer__container}>
        {Object.keys(links).map((key, index) => (
          <Fragment key={key}>
            <a href={links[key]} className={classes.footer__link}>
              {t(`${tKey}.links.${key}`)}
            </a>
            {!(index % 2)
              ? <span className={classes.footer__divider} />
              : <br />
            }
          </Fragment>
        ))}
        <a href={policyLinks.moneyBack} className={classes.footer__link}>
          {t(`${tKey}.links.money_back`)}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
