
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _map from 'lodash/map'

import { PUBLIC_ROUTES, PROTECTED_ROUTES } from 'src/constants/routes';

import { SECTIONS } from 'src/screens/book-landing/sections';

import { preloadPage } from 'src/utils/route'

import type { RootState } from 'src/store/types';

import { useRemoteConfig } from './use-remote-config';

export const usePreloadLanding = ({ loadStep }: { loadStep: number }) => {
  const { landingStack } = useRemoteConfig()

  const isLoadedApp = useSelector((state: RootState) => state.app.loaded);
  const currentStep = useSelector((state: RootState) => state.routing.currentStep)

  useEffect(() => {
    if (isLoadedApp && currentStep === loadStep) {
      Object.values({ ...PUBLIC_ROUTES, ...PROTECTED_ROUTES }).forEach((route, i) => {
        setTimeout(() => {
          preloadPage(route);
        }, i * 200)
      })

      const components = _map(landingStack, s => SECTIONS[s]);

      components.forEach((Component, i) => {
        setTimeout(() => {
          Component?.preload?.();
        }, i * 200);
      });
    }
  }, [isLoadedApp, currentStep, landingStack, loadStep]);
}
