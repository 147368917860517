import { PaymentSystem, Product as ProductDetails, Currency, ModeCardForm } from '@web-solutions/react-billing/constants';
import { RemoteConfig as ManageRemoteConfig, INITIAL_CONFIG as MANAGE_INITIAL_CONFIG } from '@web-solutions/manage-subscription/src/manage/constants/remote-config'
import { type LString } from '@web-solutions/module-localization';
import { LinkTarget } from '@web-solutions/module-attribution';
import { type SpecialOfferMode } from '@web-solutions/core/interfaces/specialOffer';
import type { Theme } from '@web-solutions/core/interfaces/theme';
import type { NoFundsOffer } from '@web-solutions/core/interfaces/remote-config';
import type { ProductAttachment } from '@web-solutions/core/interfaces/products';
import type { ExtendedWithMultiCurrencies } from '@web-solutions/core/interfaces/currencies'

import { REDIRECT_TO_BROWSER } from 'core/utils/redirect-to-browser';

import { ButtonPlacementType, ButtonBehavior, CardFormLayout, ButtonConfig } from 'core/payment/constants';

import { ModeApplePay, ModeGooglePay, ModePayPal, ErrorPopupType, ModeMercadoPago } from './billing';

export interface PolicyLinks {
  termsOfUse: string,
  privacy: string,
  moneyBack: string,
  billingTerms: string,
  address?: string,
  phone?: string,
  legalEntity?: string,
  descriptor?: string,
  faq?: string,
}

const POLICY_LINKS: PolicyLinks = {
  termsOfUse: '/terms-of-use',
  privacy: '/privacy-policy',
  moneyBack: '/money-back-policy',
  billingTerms: '/billing-terms',
};

const BTNS_CONFIGS: {
  [key in ButtonPlacementType]: ButtonConfig
} = {
  [ButtonPlacementType.PAYMENT]: {
    title: '',
    uppercaseTitle: false,
  },
  [ButtonPlacementType.PLANS]: {
    behavior: ButtonBehavior.MODAL,
    title: '',
    uppercaseTitle: false,
    enabledFlareAnimation: true,
  },
  [ButtonPlacementType.FIXED]: {
    behavior: ButtonBehavior.SCROLL,
    title: '',
    uppercaseTitle: false,
    enabledFlareAnimation: true,
  },
};

export { LString };

export interface CurrencyConfig {
  notActivePrice: string,
  activePrice: string,
  price: number,
  discount: string,
}

export type BaseOfferProductTypes<T> = ExtendedWithMultiCurrencies<{
  title: LString,
  subTitle: string,
  notActivePrice: string,
  activePrice: string,
  discount: LString,
  price: number,
  currency: Currency,
  id: T,
  readyTimer?: number,
}, CurrencyConfig>

export type ProductConfig = {
  id?: string,
  paypalPlanId?: string,
  recurlyId?: string,
  solidgateId?: string,
  paddleId?: string,
  title?: LString,
  name?: LString,
  subTitle?: LString,
  trialSelectTitle?: LString,
  descriptionText?: LString,
  badgeTitle?: LString,
  badgeCentered?: boolean,
  discount?: number,
  trialDiscount?: number,
  default?: boolean,
  textLineThrough?: LString,
  accentTitle?: LString,
  accentSubTitle?: LString,
  accentTextLineThrough?: LString,
  aboutTrialText?: LString,
  afterTrialText?: LString,
  sold?: { left: number, total: number, hiddenSubTitles?: boolean },
  promocode?: {
    discount: string,
    discountPrice: string,
  }
  smallBadges?: string[],
  trialsLeftBadge?: boolean,
  buttonTitle?: LString,
  attachment?: ProductAttachment,
  actualPriceText: string,
}

type AdditionalFields = {
  email?: {
    label: string,
    type: string,
    isRequired: boolean,
  },
  cardholder?: boolean,
  zip?: boolean,
};

export enum InjectedPaymentMethod {
  APPLEPAY = 'applepay',
  MERCADO = 'mercadopago',
  PAYPAL = 'paypal',
  CARD = 'card',
  GOOGLEPAY = 'googlepay',
}

export enum PaymentModalType {
  BASE = 'base',
  SIMPLE = 'simple',
  SIMPLE_TITLE = 'simple_title'
}

export enum PlanType {
  DEFAULT = 'default',
  SINGLE = 'single',
  PDF = 'pdf',
  TICKET = 'ticket',
  TRIAL = 'trial',
  HIDDEN = 'hidden'
}

export type PriceTypes = 'today' | 'week' | 'day'

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export interface RemoteConfig<FlowStep = string, ChatFlow = string> extends ManageRemoteConfig {
  theme: Theme,

  oneClickPaymentMode: '1click' | 'native' | 'modal' | 'native+modal',
  isOneClickFootnote: boolean,
  displayVAT: boolean,
  configVAT: {
    withGeo: boolean,
    view: "simple" | "table",
  };
  linkTarget: LinkTarget,
  planType: PlanType,
  plansFeatures: string[],
  paymentDescriptionType: 'old' | 'new' | 'icons'
  showTimerInPlanBadge: boolean,
  paymentProject: string,
  paymentSystem: PaymentSystem,
  paymentModalHeader: {
    title?: LString,
    subtitle?: LString
  },
  simpleTitleModal: {
    type: 'default' | 'short',
    titleEnabled: boolean,
    text: LString,
    fontSize?: number;
    withTotalPriceUnder?: boolean;
  },
  paymentSystemReserve: PaymentSystem | '',
  products: ProductConfig[],
  productsDetails: Array<Omit<ProductDetails, 'period'> & { period: 'ONETIME' }>,
  additionalFields: AdditionalFields,
  policyLinks: PolicyLinks,
  address: string,
  buttonsConfigs: typeof BTNS_CONFIGS,
  productsTitle: LString,
  showFeaturesBlock: boolean,
  showPricesBlock: boolean,
  showFullPrice: boolean,
  showPriceDiscount: boolean,
  openPaymentModalOnProductClick: boolean,
  isShowSafeCheckout: boolean,
  discountTime: number,
  modePayPal: ModePayPal,
  modeApplePay: ModeApplePay,
  modeGooglePay: ModeGooglePay,
  modeMercadoPago: ModeMercadoPago,
  cardFormCollapsed: boolean,
  cardFormLayout: CardFormLayout,
  showPaymentSafeAtPlans: boolean,
  showTermsAtPaymentModal: boolean,
  totalPriceType: PriceTypes,
  redirectToSuccessOnDecline: boolean,
  paymentModalType: PaymentModalType,
  trialInfoBlock: {
    show: boolean,
    periodMode: "day" | "week"
  },
  injectedPaymentMethods: InjectedPaymentMethod[][],
  defaultInjectedPaymentMethod: InjectedPaymentMethod[],
  quizPaymentModalFullMode: boolean,
  errorPopup: {
    enabled: boolean,
    buttonTitle: string,
    type: ErrorPopupType,
    isDetailedErrors: boolean
  },
  withOnlyEngFootnote: boolean,
  flow: Array<FlowStep | FlowStep[]>,
  isTrialPeriodReminder: boolean,
  trialsLeft: {
    badgeButtonEnabled: boolean,
    textEnabled: boolean,
    time: number,
  },
  specialOffer: {
    mode: SpecialOfferMode,
    enabled: boolean,
    time: number,
    prevDiscount: number,
    discount: number,
    showAfterCloseAmount: number,
    products: ProductConfig[],
    scrollToTopAfterTrigger?: boolean
    title?: LString,
    soInfoOfferText?: LString,
  },
  noFundsOffer: NoFundsOffer,
  redirectToBrowser: REDIRECT_TO_BROWSER,
  isSendingTerminateLinks: boolean,
  isSendingTrialEnd: boolean,
  isTrialPriceSelection: boolean,
  chatFlow: {
    flow: Array<ChatFlow>;
    welcomeAnswerType: 'single' | 'multi' | 'none',
    symbolsPerSecond: number,
    palmEnabled: boolean,
    redirectToSummary: boolean,
    nameStepEnabled: boolean,
    buttonText: LString,
    answersStyle?: 'default' | 'with_border',
    branchingMode?: {
      isLongRelationshipFlow: boolean
    }
  };
  modeCardForm: ModeCardForm;
  emailReportlLinkMode: 'download' | 'open' | 'download+open',
  cameraWithTorch: boolean,
  emailWithJaSymbols: boolean,
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...MANAGE_INITIAL_CONFIG,
  withOnlyEngFootnote: false,
  paymentDescriptionType: 'new',
  isOneClickFootnote: true,
  displayVAT: false,
  planType: PlanType.DEFAULT,
  paymentModalHeader: {
    title: '',
    subtitle: ''
  },
  simpleTitleModal: {
    type: 'default',
    titleEnabled: true,
    text: '<b>91% found</b> their happiness with highly-personalized predictions',
    fontSize: 22,
    withTotalPriceUnder: true
  },
  plansFeatures: [],
  flow: [],
  linkTarget: LinkTarget.DEFAULT,
  showTimerInPlanBadge: false,
  paymentProject: '',
  paymentSystem: PaymentSystem.RECURLY,
  paymentSystemReserve: '',
  products: [],
  productsDetails: [],
  additionalFields: {},
  policyLinks: POLICY_LINKS,
  address: 'A.Gustaicio g.23-255a, Vilnius zip: LT-02188 Lietuvos Respublika',
  buttonsConfigs: BTNS_CONFIGS,
  productsTitle: { en: '' },
  showPricesBlock: true,
  showPriceDiscount: true,
  openPaymentModalOnProductClick: true,
  isShowSafeCheckout: true,
  discountTime: 120,
  modePayPal: ModePayPal.ENABLED,
  modeApplePay: ModeApplePay.ENABLED,
  modeGooglePay: ModeGooglePay.DISABLED,
  modeMercadoPago: ModeMercadoPago.DISABLED,
  cardFormCollapsed: false,
  cardFormLayout: CardFormLayout.COMPACT,
  showFeaturesBlock: false,
  showFullPrice: false,
  showPaymentSafeAtPlans: false,
  showTermsAtPaymentModal: false,
  totalPriceType: 'today',
  paymentModalType: PaymentModalType.BASE,
  trialInfoBlock: {
    show: false,
    periodMode: "day"
  },
  redirectToSuccessOnDecline: false,
  defaultInjectedPaymentMethod: [InjectedPaymentMethod.CARD],
  injectedPaymentMethods: [[InjectedPaymentMethod.APPLEPAY, InjectedPaymentMethod.GOOGLEPAY, InjectedPaymentMethod.PAYPAL, InjectedPaymentMethod.MERCADO], [InjectedPaymentMethod.CARD]],
  quizPaymentModalFullMode: false,
  errorPopup: {
    enabled: false,
    buttonTitle: '',
    type: ErrorPopupType.CARD,
    isDetailedErrors: false
  },
  isTrialPeriodReminder: false,
  trialsLeft: {
    badgeButtonEnabled: false,
    textEnabled: false,
    time: 5000,
  },
  specialOffer: {
    mode: 'with_plans',
    enabled: false,
    time: 100,
    prevDiscount: 40,
    discount: 50,
    products: [],
    scrollToTopAfterTrigger: false,
    showAfterCloseAmount: 1,
  },
  noFundsOffer: {
    enabled: false,
    time: 100,
    discount: 50,
    products: [],
    buttonTitle: ''
  },
  redirectToBrowser: REDIRECT_TO_BROWSER.DISABLED,
  isSendingTerminateLinks: false,
  isSendingTrialEnd: false,
  isTrialPriceSelection: false,
  chatFlow: {
    flow: [],
    answersStyle: 'default',
    welcomeAnswerType: 'none',
    symbolsPerSecond: 8,
    palmEnabled: true,
    redirectToSummary: false,
    nameStepEnabled: true,
    buttonText: '',
    branchingMode: {
      isLongRelationshipFlow: true
    }
  },
  theme: {},
  oneClickPaymentMode: '1click',
  modeCardForm: ModeCardForm.ENABLED,
  emailReportlLinkMode: 'download',
  configVAT: {
    withGeo: true,
    view: "simple",
  },
  cameraWithTorch: false,
  emailWithJaSymbols: false,
};
