import { type FC, useCallback } from 'react'

import { TermsMenuMediator as CoreTermsMenuMediator, type TermsMenuMediatorProps as CoreTermsMenuMediatorProps } from '@web-solutions/core/containers/terms-menu/terms-menu-mediator';

interface TermsMenuMediatorProps extends Omit<CoreTermsMenuMediatorProps, 'showTermsAdditionalEffect'> { }

export const TermsMenuMediator: FC<TermsMenuMediatorProps> = (props) => {
  const handleOpenMenu = useCallback((isOpen: boolean) => {
    const rootEl = document.getElementById('root')

    if (rootEl) {
      rootEl.style.overflow = isOpen ? 'hidden' : 'auto';
    }
  }, [])

  return (
    <CoreTermsMenuMediator
      theme='dark'
      onOpenMenu={handleOpenMenu}
      {...props}
    />
  )
}
