import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { t } from '@web-solutions/module-localization';

import type { ThemeMode } from '@web-solutions/core/interfaces/theme';

import { SideBar } from 'core/ui-elements';
import { useRemoteConfig } from 'core/hooks/use-remote-config';

//@ts-ignore
import { SUPPORT_EMAIL } from 'src/constants/general';

import { TermsMenuContextProvider } from './context';

import classes from './style.module.scss';

const tKey = 'core.terms_info';

interface Props {
  theme?: ThemeMode,
  show: boolean,
  onContactClick?: () => void,
  onOpenMenu?: (open: boolean) => void,
  customItem?: React.ReactNode,
  className?: string,
}

type Links = { [key: string]: string | undefined };

export const TermsMenu: React.FC<Props> = ({ theme, show, onContactClick, onOpenMenu, customItem, className }) => {
  const [open, setOpen] = useState(false);
  const { policyLinks } = useRemoteConfig();
  const onOpenMenuRef = useRef<(open: boolean) => void>();
  onOpenMenuRef.current = onOpenMenu;

  useEffect(() => {
    onOpenMenuRef.current?.(open);
  }, [open]);

  const links: Links = {
    privacy_policy: policyLinks?.privacy,
    terms: policyLinks?.termsOfUse,
    billing_terms: policyLinks?.billingTerms,
    money_back: policyLinks?.moneyBack,
    faq: policyLinks?.faq
  };

  const handleVisibilityChange = () => {
    Analytics.trackEvent('terms_menu', open ? 'close' : 'open');
    setOpen(!open);
  }

  const handleContactClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onContactClick) {
      e.preventDefault();
      onContactClick();
    } else {
      handleClick(e);
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    //@ts-ignore
    Analytics.trackEvent(`terms_menu_${e.target?.id}`, 'click');
    setOpen(!open);
  }

  return (
    <TermsMenuContextProvider value={{ open, setOpen }}>
      <div className={classNames(classes.pageContainer, className, { [classes.isOpen]: open })}>
        {show && (
          <SideBar
            onVisibilityChange={handleVisibilityChange}
            visible={open}
            theme={theme}
          >
            <div className={classes.content}>
              <a href={`mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent('Terms Menu')}`} id='contact_us' target="__blank" onClick={handleContactClick}>
                {t(`${tKey}.contact_us`)}
              </a>
              {Object.entries(links).map(([key, value]) => (
                value && (
                  <a key={key} id={key} href={value} target="__blank" onClick={handleClick}>
                    {t(`${tKey}.${key}`)}
                  </a>
                )
              ))}
              {customItem}
            </div>
          </SideBar>
        )}
      </div>
    </TermsMenuContextProvider >
  );
};
