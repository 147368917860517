import { Suspense, type FC } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Loader } from '@web-solutions/core/components/loader';

import { PROTECTED_ROUTES, PUBLIC_ROUTES } from 'src/constants/routes';
import { SCREENS } from 'src/constants/screens';

import { ProtectedRoute } from 'src/hocs/protected-route';

import { usePreloadLanding } from 'src/hooks/use-preload-landing';

export const LandingRouter: FC = () => {
  const location = useLocation()

  usePreloadLanding({ loadStep: 0 })

  return (
    <Routes location={location}>
      <Route element={<ProtectedRoute />}>
        {Object.values(PROTECTED_ROUTES).map((r, i) => {
          const Screen = SCREENS[r]

          return (
            <Route key={i} path={r} element={
              <Suspense fallback={<Loader />}>
                {Screen && <Screen />}
              </Suspense>
            } />
          )
        })}
      </Route>
      {
        Object.values(PUBLIC_ROUTES).map((r, i) => {
          const Screen = SCREENS[r]

          return (
            <Route key={i} path={r} element={
              <Suspense fallback={<Loader />}>
                {Screen && <Screen />}
              </Suspense>
            } />
          )
        })
      }
    </Routes>
  )
}
