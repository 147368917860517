import {createContext, useContext} from 'react';

export interface TermsMenuContext {
  open: boolean,
  setOpen: (val: boolean) => void,
}

const termsMenuContext = createContext({} as TermsMenuContext);
export const TermsMenuContextProvider = termsMenuContext.Provider

export const useTermsMenuContext = () => {
  const context = useContext(termsMenuContext);
  return context;
}