import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { quizProgressSelector } from 'core/store/quiz/selectors';

//@ts-ignore
import { SUBSCRIPTION } from 'src/constants/routes';
//@ts-ignore
import { SUBSCRIPTION_NAMES } from 'src/constants/screens';

export const useShowTermsMenu = (): boolean => {
  const location = useLocation();

  const quizProgress = useSelector(quizProgressSelector);

  //@ts-ignore
  const isSuccess = location?.pathname === SUBSCRIPTION[SUBSCRIPTION_NAMES.SUCCESS];
  const isFirst = quizProgress?.prevStep! < 0;

  const isShowTermsMenu = isFirst || isSuccess;

  return isShowTermsMenu;
}