import type { FC } from "react";
import lazy, { PreloadableComponent } from 'react-lazy-with-preload';

import { QUIZ_NAMES, QuizNameKeys, ROUTES } from "./routes";

export const SCREENS: Partial<Record<string, PreloadableComponent<FC<any>>>> = {
  [ROUTES.MAIN]: lazy(() => import('src/screens/book-landing')),
  [ROUTES.SHIPPING]: lazy(() => import('src/screens/shipping')),
  [ROUTES.SUCCESS]: lazy(() => import('src/screens/order/order-success')),
  [ROUTES.CART]: lazy(() => import('src/screens/cart')),
};

export const QUIZ_SCREENS: Partial<Record<QuizNameKeys, PreloadableComponent<FC<any>>>> = {
  [QUIZ_NAMES.QUIZ_WELCOME]: lazy(() => import('src/screens/quiz/gender/common')),

  [QUIZ_NAMES.QUIZ_BIRTHDAY]: lazy(() => import('src/screens/quiz/birthdate/birthdate-wheel')),
  [QUIZ_NAMES.QUIZ_BIRTHTIME]: lazy(() => import('src/screens/quiz/birthtime/birthtime-wheel')),
  [QUIZ_NAMES.QUIZ_BIRTHPLACE]: lazy(() => import('src/screens/quiz/birthplace')),
  [QUIZ_NAMES.QUIZ_PALM_READING]: lazy(() => import('src/screens/quiz/palm-reading')),

  [QUIZ_NAMES.QUIZ_TRANSITION_HAND]: lazy(() => import('src/screens/quiz/transitions/hand')),
  [QUIZ_NAMES.QUIZ_TRANSITION_MAP]: lazy(() => import('src/screens/quiz/transitions/map')),

  [QUIZ_NAMES.QUIZ_SIGNS_MAGIC]: lazy(() => import('src/screens/quiz/magic')),

  [QUIZ_NAMES.QUIZ_MAILING]: lazy(() => import('src/screens/quiz/mailing')),
}

export const SUBSCRIPTION_NAMES = {}