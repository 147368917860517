import {
  INITIAL_CONFIG as CORE_INITIAL_CONFIG,
  type RemoteConfig as CoreRemoteConfig,
  type Nullable,
  LString
} from 'core/constants/remote-config';

import { SummarySection } from 'src/types/remote-config';

import { QuizNamesValues } from 'src/constants/routes';
import { ShopProduct } from 'src/store/cart/types';

import { ProductType } from './products';

export type OfferProductId = ProductType

export type Flow = QuizNamesValues;

export interface RemoteConfig extends CoreRemoteConfig<Flow[number]> {
  landingStack: Array<SummarySection>,
  availableShippingCountries: string[],
  genderPageTitle: LString,
  welcomeGenderType: 'image' | 'video',
  shopProducts: ShopProduct[],
  birthPlaceData: {
    suggestCurrentPlace: boolean,
  }
  magicDurationLoad: number,
  cameraInitTimeout: number,
  isPalmDisclaimerVisible: boolean,

  resetBirthDateTime?: boolean

  birthPlacePage: {
    required: boolean,
    suggestCurrentPlace: boolean,
  }
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...CORE_INITIAL_CONFIG,
  landingStack: [],
  shopProducts: [
    {
      notActivePrice: '50',
      activePrice: '40',
      id: ProductType.BIRTH_CHART_HARDCOVER,
      discount: '25',
      price: 0,
      currency: "USD"
    },
    {
      notActivePrice: '25',
      activePrice: '20',
      id: ProductType.BIRTH_CHART,
      discount: '',
      price: 0,
      currency: "USD"
    }
  ],
  genderPageTitle: '',
  welcomeGenderType: 'image',
  availableShippingCountries: [],
  flow: [],
  birthPlaceData: {
    suggestCurrentPlace: false,
  },
  magicDurationLoad: 12000,
  cameraInitTimeout: 4000,
  isPalmDisclaimerVisible: false,

  resetBirthDateTime: false,

  birthPlacePage: {
    required: true,
    suggestCurrentPlace: false,
  },
};

export type FullConfig = Nullable<Partial<RemoteConfig>>;